'use client';
import Link from 'next/link';
import React from 'react';

import Icon from '@/components/v3/Icon';

import ContentFooterWithoutRoutes from '../shared/ContentFooterWithoutRoutes';

const OpenAccountPage: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="overflow-auto w-full h-full bg-display-100">
      <div className="flex md:hidden absolute inset-x-0 top-0 justify-center h-[68px]">
        <div className="flex justify-between items-center py-10 px-24 w-full bg-display-0 border-b border-display-200 border-solid">
          <Link href="/" aria-label="Link para página inicial">
            <Icon
              name="logo/stone"
              className="w-[92px] h-[24px] fill-current"
            />
          </Link>
        </div>
      </div>
      {children}
      <ContentFooterWithoutRoutes />
    </div>
  );
};
export default OpenAccountPage;
